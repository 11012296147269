export const localization = {
  ru: {
    Landing: {
      massHandlingBtn: 'Массовая обработка',
      deliveryTabName: 'Отправка',
      processingTabName: 'Обработка',

      languageDropdownRus: 'RUS',
      languageDropdownEng: 'USA'
    },
    Card: {
      paymentTitle: 'Оплаченные',
      productName: 'Иммунитет захваченной территории на 2 секунды.'
    },
    MapUIAbilitiesTooltip: {
      type: 'Тип',
      passive: 'пассивная'
    }

  },
  en: {
    Landing: {
      massHandlingBtn: 'Mass handling',
      deliveryTabName: 'Shipping',
      processingTabName: 'Processing',

      languageDropdownRus: 'RUS',
      languageDropdownEng: 'USA'
    }
  }
};
