import { Component, OnInit } from '@angular/core';
import { Auth } from '../../../services/authorisation.service';
import { User } from '../../../models/user.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { LocalizationService } from '../../../services/localization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  user: User;
  loginCheckUrlPath = 'login_check';
  // loginCheckUrlPath = '/authenticate';  // old auth url
  // apiUrl = 'http://localhost:5000/api/authenticate'; // old auth url
  apiUrl = environment.apiUrl + this.loginCheckUrlPath;
  email: string;
  password: string;
  errorMessage: string;
  private cookieValue: string;

  // inject dependency for cookie and authorisation services
  constructor(
    protected auth: Auth,
    private cookieService: CookieService,
    private localizationService: LocalizationService,
  ) { }

  ngOnInit(): void {
  }

  async doAuth() {
    try {
      // get email and password from keyboard
      const user = {
        username: this.email,
        password: this.password,
      };

      this.auth.post(this.apiUrl, user).subscribe(response => {
        if (!response['token']) { throw new Error('Неверный логин/пароль'); }

        // set token and language into cookie
        this.cookieService.set('token', response['token']);

        // set default language into cookie
        this.localizationService.setLanguageIntoCookie('en');

        location.replace('/orders/massHandling');
      });
    } catch (error) {
      console.log(error.message);
      this.errorMessage = error.message;
      setTimeout(() => {
        this.errorMessage = '';
      }, 3000);
    }
  }
}
