<p class="card__title">{{ data.name }}   {{ data.groupSum }} шт</p>
<div class="orders-group" *ngFor="let group of data.groups; index as i">
  <a target="_self"
     (click)="drawSortedOrdersTable([group.name, data.name])"
  >
    <span class="orders-group__name">"{{ group.name }}"</span>
  </a>

  <div class="order__container">
    <div class="product__container">
      <img
        class="product__image"
        src="./assets/icon/bear.png"
        width="36"
        height="36"
        alt="Good image"
      />

      <div class="product__info">
              <span class="product__quantity">
                {{ group.quantity }} шт.
              </span>
        <div class="order__location">
          <img
            class="product__image location__icon"
            src="./assets/icon/target.png"
            width="10"
            height="10"
            alt="Target icon"
          />
          {{ group.location }}
        </div>
      </div>
    </div>
  </div>
</div>
<hr>
