import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-successful-payment',
  templateUrl: './successful-payment.component.html',
  styleUrls: ['./successful-payment.component.scss']
})

export class SuccessfulPaymentComponent implements OnInit {
  data: any;

  supportData = {
    phone: '+48 508 38 83',
    email: 'support@support.pl'
  };

  id: number;
  private subscription: Subscription;

  constructor(
    protected apiService: ApiService,
    private activateRoute: ActivatedRoute,
    protected router: Router,
  ) {
    this.subscription = activateRoute.params.subscribe(params => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    this.disableHeaderAndFooter();
    this.getData();
  }

  // get data from server
  getData(): any {
    console.log('WORKING');
    const id = this.getIdFromURL();
    this.apiService.get('orders/' + id + '/ecwid').subscribe(response => {
      console.log('RESPONSE: ', response);

      // if no response taken -> redirect to pageNotFound
      if (!response) {
        this.router.navigate(['**']);
      }
      this.data = response;
    });
  }

  // parse id from url
  getIdFromURL(): number {
    const arr = this.router.url.split('/');
    return +arr[arr.length - 1];
  }

  disableHeaderAndFooter(): void {
    document.getElementById('header').style.display = 'none';
    document.getElementById('footer').style.display = 'none';
  }
}
