<ng-container *ngIf="massHandlingList">
  <div
    class="wrapper__table mat-elevation-z8">
    <table mat-table [dataSource]="massHandlingList" class="mat-elevation-z8">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date</th>
        <td mat-cell
            class="date"
            *matCellDef="let element; let i = index">
          <a class="button"
             [routerLink]="['/orders/massHandling/history/', i + 1]"
          >{{element.createdAt}}</a>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let element"> Success/Error({{getRatioOfSuccessAndError(element.items)}})
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
    </table>

    <mat-paginator
      #matPaginator
      [pageSizeOptions]="paginatorData.pageSizeOptions"
      [length]="paginatorData.massHandlingListPaginator.length"
      [pageSize]="paginatorData.massHandlingListPaginator.pageSize"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

