import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor() { }

  // создаём юзера (пока что здесь)
  user = {
    email: null,
    password: null,
    token: localStorage.getItem('token'),
  };

  ngOnInit(): void {
  }

}
