import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-card-to-delivery',
  templateUrl: './card-to-delivery.component.html',
  styleUrls: ['./card-to-delivery.component.scss']
})
export class CardToDeliveryComponent implements OnInit {
  @Input() data;
  @Output() toBeSent = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
  }

  drawSortedOrdersTable(params): void {
    this.toBeSent.emit(params);
  }

  print(params): void {
    // send category name and product name to the server
    this.apiService.downloadFile('orders/massHandling', params).subscribe(response => {

      // create date for the file name
      const date = new Date();
      // generate string like '04-12-2020 Bear'
      const dateName = date.getDate().toString() + '-' + date.getMonth().toString() + '-' + date.getFullYear() + ' ' + params.name;
      // concatenate file name
      const blob: any = new Blob([response], { type: 'application/vnd.ms-word' });
      fileSaver.saveAs(blob, dateName + '.docx');
    });
  }

}
