<div class="wrapper__section">
  <div class="delivery__selection">
    <div class="time__section">
      <img
        class="time__icon"
        src="./assets/icon/clock.png"
        width="20"
        height="20"
        alt="Clock icon"
      />
      <p>вчера 11:00 – сегодня 11:00</p>
    </div>
    <div class="menu__radio-button">
      <mat-radio-group name="delivery">
        <mat-radio-button *ngFor="let button of checkboxesForDelivery"
                          [value]="button.name"
                          [checked]="button.checked"
                          [disabled]="button.disabled"
                          class="example-radio-button"
                          (change)="radioChange($event)">
          {{button.name}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<div class="wrapper content" *ngIf="isVisible; else groups">
  <mat-tab-group
    *ngIf="data"
    animationDuration="0ms"
    class="mat-tab-group"
    mat-align-tabs="end"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'table.toHandle' | translate }} ({{ data.toShip.sum }})
      </ng-template>

      <div
        class="mat-tab__content"
      >
        <app-card-to-delivery
          *ngFor="let card of data.toShip.categories"
          [data]="card"
          class="card__container"
          (toBeSent)="toBeSent($event)"
        >
        </app-card-to-delivery>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'table.toProcessing' | translate }} ({{ data.toHandle.sum }})
      </ng-template>

      <div
        class="mat-tab__content"
        *ngIf="data.toHandle.categories.length"
      >
        <app-card-to-processing
          *ngFor="let card of data.toHandle.categories"
          [data]="card"
          class="card__container"
          (toBeSent)="toBeSent($event)"
        >
        </app-card-to-processing>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #groups>
  <div class="wrapper wrapper__layout bread-crumbs bread-crumbs__back">
    <span class="bread-crumbs__text" (click)="toggle()">{{ paymentStatusCrumb }}</span>
    <img
      class="time__icon"
      src="./assets/icon/arrow-right.svg"
      width="23"
      height="25"
      alt="Clock icon"
    />
    <span class="bread-crumbs__text bread-crumbs__text_colored">{{ goodNameCrumb }}</span>
  </div>
  <!-- ORDERS TABLE COMPONENT -->
  <app-orders-table class="wrapper wrapper__layout"
    [filterParams]="filterParams"
  ></app-orders-table>
</ng-template>
