<div *ngIf="data" class="wrapper">
  <div class="congrats">
    <h3>Than you for your order!</h3>
    <p>Order confirmation and updates will be sent to {{data.email}} </p>
    <hr>
  </div>
  <div class="order__info">
    <h3>Order {{data.id}}</h3>
    <p>{{data.createDate}}</p>
    <hr>
  </div>
  <div class="order__shipment">
    <h3>Order will be shipped to</h3>
    <p>{{data.shippingPerson.name}}</p>
    <p>{{data.shippingPerson.street}},
      {{data.shippingPerson.postalCode}} {{data.shippingPerson.city}}
      , {{data.shippingPerson.countryName}}</p>
    <hr>
  </div>
  <div class="order__shipment-method">
    <h3>Shipping method</h3>
    <p>{{data.shippingOption.shippingMethodName}}
      <br>
      ({{data.shippingOption.estimatedTransitTime}})</p>
    <hr>
  </div>
  <div class="order__description">
    <h3>Your order</h3>
    <div class="order__content">
      <img src="../../../../assets/image/Bear-image.png" alt="Bear image" width="80" height="80">
      <div class="order__content-info" *ngFor="let order of data.items">
        <p>{{order.name}}</p>
        <p>{{order.selectedOptions[0].name}}: {{order.selectedOptions[0].value}}</p>
        <p>&times; {{order.quantity}}</p>
      </div>
    </div>
  </div>
  <hr>
  <div class="order__questions">
    <h3>Questions on your order?</h3>
    <p>We're here for you. Let us know how we can help</p>
    <p>Phone:
      <a href="tel:{{supportData.phone}}"> {{supportData.phone}}</a>
    </p>
    <p>E-mail:
      <a href="mailto:{{supportData.email}}"> {{supportData.email}}</a>
    </p>
  </div>
  <hr>
  <div class="submit">
    <a href="https://lamourbears.com/">
      <button class="button button_bordered button_big" type="submit">
        Continue Shopping
      </button>
    </a>
  </div>

</div>
