import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-card-to-processing',
  templateUrl: './card-to-processing.component.html',
  styleUrls: ['./card-to-processing.component.scss']
})
export class CardToProcessingComponent implements OnInit {
  @Input() data;
  @Output() toBeSent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  drawSortedOrdersTable(event): void {
    this.toBeSent.emit(event);
  }
}
