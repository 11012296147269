import {Component, AfterViewInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-mh-history',
  templateUrl: './mh-history.component.html',
  styleUrls: ['./mh-history.component.scss']
})
export class MhHistoryComponent implements AfterViewInit {
  itemsList: MatTableDataSource<any>;
  massHandlingList: MatTableDataSource<any>;
  successCount: number;
  response;

  displayedColumns2 = ['id', 'date', 'status'];

  paginatorData = {
    pageSizeOptions: [5, 10, 20, 25],
    massHandlingListPaginator: {
      pageSize: 20,
      length: 100,
    },
  };

  private paginator: MatPaginator;

  constructor(
    protected apiService: ApiService,
  ) {
    this.getMHList();
  }

  ngAfterViewInit(): void {
    this.massHandlingList.paginator = this.paginator;
    this.massHandlingList.paginator.length = this.paginatorData.massHandlingListPaginator.length;
    this.massHandlingList.paginator.pageSize = this.paginatorData.massHandlingListPaginator.pageSize;
  }

  getCountOfSuccessfulMassHandlingOrders(item): number {
    return item.filter(elem => elem.status === 'SUCCESS').length;
  }
  getCountOfErrorMassHandlingOrders(item): number {
    return item.filter(elem => elem.status === 'ERROR').length;
  }
  getRatioOfSuccessAndError(item): string {
    const totalCount = item.length;
    const successCount = item.filter(elem => elem.status === 'SUCCESS').length;
    return `${successCount} / ${totalCount - successCount}`;
  }

  getMHList(): void {
    this.apiService.get('orders/massHandling').subscribe(response => {

      this.response = response;

      // define list of mass handling items
      this.massHandlingList = new MatTableDataSource<any>(response.items);

      // set pagination options
      this.paginatorData.massHandlingListPaginator.pageSize = response.perPage;
      this.paginatorData.massHandlingListPaginator.length = response.total;

      // calculate count of successful handling
      this.successCount = this.getCountOfSuccessfulMassHandlingOrders(response.items[0].items);
    });
  }
}
