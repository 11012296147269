<div class="wrapper">
  <div class="header__wrapper">
    <div class="header">
      <nav>
        <ul>
          <li>
            <a class="button button_router"
               [routerLink]="['/orders/massHandling']"
               routerLinkActive="button_active"
               [routerLinkActiveOptions]="{ exact: true }"
            >{{ 'headerMenu.massHandling' | translate | titlecase }}</a>
          </li>
          <li>
            <a class="button button_router"
               [routerLink]="['/orders']"
               routerLinkActive="button_active"
               [routerLinkActiveOptions]="{ exact: true }"
            >{{ 'headerMenu.orders' | translate | titlecase }}</a>
          </li>
          <li>
            <a class="button button_router"
               [routerLink]="['/orders/massHandling/history']"
               routerLinkActive="button_active"
               [routerLinkActiveOptions]="{ exact: true }"
            >{{ 'headerMenu.logs' | translate | titlecase }}</a>
          </li>
        </ul>
      </nav>
      <div class="buttons__container">
        <label>
          <select
            #langSelect
            (change)="onLanguageChange(langSelect.value)"
            class="button button_bordered header__lang"
          >
            <option *ngFor="let lang of localizationService.translate.getLangs()"
                    [value]="lang"
                    [selected]="lang === localizationService.translate.currentLang"
            >
              {{ lang }}
            </option>
          </select>
        </label>
        <div class="logout">
          <a class="button button_logout" (click)="logout()">
            <img
              src="./assets/icon/user.png"
              width="15"
              height="15"
              alt="Good image"
            />
            {{ 'button.logout' | translate | titlecase }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
