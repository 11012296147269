import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Auth} from './services/authorisation.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    protected auth: Auth,
    public router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    // for unregistered users
    if (!this.isToken()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  isToken(): boolean {
    const token = this.auth.checkToken();
    return !!token;
  }

}
