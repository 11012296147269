import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {DemoMaterialModule} from '../material-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './components/user/user.component';
import { HeaderComponent } from './pages/layouts/header/header.component';
import { FooterComponent } from './pages/layouts/footer/footer.component';
import { MassHandlingComponent } from './pages/views/mass-handling/mass-handling.component';
import { OrdersComponent } from './pages/views/orders/orders.component';
import { LoginComponent } from './pages/views/login/login.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CardToProcessingComponent } from './components/mass-handling/cards/card-to-processing/card-to-processing.component';
import { CardToDeliveryComponent } from './components/mass-handling/cards/card-to-delivery/card-to-delivery.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SuccessfulPaymentComponent } from './pages/webSite/successful-payment/successful-payment.component';
import { NotFoundComponent } from './pages/views/not-found/not-found.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MhHistoryTableComponent } from './pages/views/mh-history-table/mh-history-table.component';
import { MhHistoryComponent } from './pages/views/mh-history/mh-history.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): unknown {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    HeaderComponent,
    FooterComponent,
    MassHandlingComponent,
    OrdersComponent,
    LoginComponent,
    CardToProcessingComponent,
    CardToDeliveryComponent,
    SuccessfulPaymentComponent,
    NotFoundComponent,
    OrdersTableComponent,
    MhHistoryTableComponent,
    MhHistoryComponent,
  ],
  imports: [
    DemoMaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
