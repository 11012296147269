import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { LoginGuard } from './login-guard.service';

import { LoginComponent } from './pages/views/login/login.component';
import { MassHandlingComponent } from './pages/views/mass-handling/mass-handling.component';
import { OrdersComponent } from './pages/views/orders/orders.component';
import { SuccessfulPaymentComponent } from './pages/webSite/successful-payment/successful-payment.component';
import { NotFoundComponent } from './pages/views/not-found/not-found.component';
import { MhHistoryComponent } from './pages/views/mh-history/mh-history.component';
import { MhHistoryTableComponent } from './pages/views/mh-history-table/mh-history-table.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [LoginGuard] },
  { path: 'orders', component: OrdersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'orders/massHandling', component: MassHandlingComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'orders/massHandling/history', component: MhHistoryComponent, pathMatch: 'full', canActivate: [AuthGuard], children: [{
    path: ':id', component: MhHistoryComponent, pathMatch: 'full', canActivate: [AuthGuard]
    }] },
  { path: 'payment/successful/:id', component: SuccessfulPaymentComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'orders/massHandling/history/:id', component: MhHistoryTableComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
