import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class Auth {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {
  }

  // отправляем запрос на сервер, body = {user}
  post(path = '', body, params = {}): any {
    const httpOptions = {
      params: {},
      headers: { 'Content-Type': 'application/json' }
    };

    if (params) {
      httpOptions.params = params;
    }

    return this.httpClient.post(path, body, httpOptions);
  }

  checkToken(): string {
    return this.cookieService.get('token');
  }
}
