import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mh-history-table',
  templateUrl: './mh-history-table.component.html',
  styleUrls: ['./mh-history-table.component.scss']
})
export class MhHistoryTableComponent {
  private routeSubscription: Subscription;
  id;

  itemsList: MatTableDataSource<any>;

  displayedColumns = ['id', 'date', 'name', 'paymentMethod', 'status', 'country'];

  constructor(
    protected apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.getItems();
  }

  // methods are to get info from "response"
  getCountry(item): object { // todo: get country from response
    return JSON.parse(item.order.ecwidOrder).shippingPerson.countryName;
  }
  getName(item): string { // todo: get name from response
    return JSON.parse(item.order.ecwidOrder).shippingPerson.name;
  }

  getItems(): void { // get items list from API
    this.apiService.get('orders/massHandling').subscribe(response => {
      this.getId();
      this.defineItemsList(response);
    });
  }

  getId(): void { // get id from url
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params.id - 1;
    });
  }

  defineItemsList(response): void {
    if (this.id >= response.items.length) { // redirect to pageNotFound
      this.router.navigate(['**']);
      return;
    }
    this.itemsList = new MatTableDataSource<any>(response.items[this.id].items);
  }
}
