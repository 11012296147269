import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {OrderModel} from '../../../models/order/order.model';
import {MatTableDataSource} from '@angular/material/table';

export interface MassHandlingInterface {
  toHandle: unknown;
  toShip: unknown;
}

@Component({
  selector: 'app-mass-handling',
  templateUrl: './mass-handling.component.html',
  styleUrls: ['./mass-handling.component.scss']
})

export class MassHandlingComponent implements OnInit {
  filterParams;
  dataSource: MatTableDataSource<unknown>;

  isVisible = true;
  paymentStatusCrumb: string;
  goodNameCrumb: string;

  data: MassHandlingInterface;

  // response sorted orders example
  checkboxesForDelivery = [
    {
      name: 'UPS',
      value: '1',
      checked: true,
      disabled: false,
    },
    {
      name: 'Inpost',
      value: '2',
      checked: false,
      disabled: true,
    },
    {
      name: 'DPD',
      value: '3',
      checked: false,
      disabled: true,
    },
    {
      name: 'DHL',
      value: '4',
      checked: false,
      disabled: true,
    },
  ];
  selectedDeliveryCompany = this.checkboxesForDelivery[0].name;

  // get cards toShip and toHandle from server
  getGroups(): void {
    this.apiService.get('orders/groupedOrders').subscribe(groups => {
      this.data = groups;
    });
  }

  // handle checkbox change
  radioChange(event): void {
    this.selectedDeliveryCompany = event.value;
  }

  constructor(
    protected apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.getGroups();
  }

  // if awaiting response show "Loading..."
  toggle(): void {
    this.isVisible = !this.isVisible;
    if (this.isVisible) {
      this.getGroups();
    }
  }

  // send params
  toBeSent(params): void {
    this.goodNameCrumb = params.name;
    this.paymentStatusCrumb = params.category;

    // set filter parameters to get filtered orders list
    this.filterParams = {
      category: params.category,
      productSku: params.name,

      // pagination params
      page: params.page,
      perPage: params.perPage  // todo set 200
    };

    switch (params.category) {
      case 'Оплаченные': {
        this.filterParams.paymentStatus = 'PAID';
        this.filterParams.processingStatus = 'AWAITING_PROCESSING';
        this.filterParams.source = 'not 17191128';
        break;
      }
      case 'Оплата при получении, Klarna Pay Later': {
        this.filterParams.klarnaAndCODForShipping = true;
        break;
      }
      case 'С комментариями': {
        this.filterParams.clientComment = 'not empty';
        break;
      }
    }


    this.toggle();
  }
}
