<p class="card__title">{{ data.name }}   {{ data.groupSum }} шт</p>
<div class="orders-group" *ngFor="let group of data.groups">
  <a target="_self"
     (click)="drawSortedOrdersTable({name: group.group, category: data.name, page: 1, perPage: 20})"
  >
    <span class="orders-group__name">"{{ group.name }}"</span>
  </a>

  <div class="order__container">
    <div class="product__container">
      <img
        class="product__image"
        src="./assets/icon/bear.png"
        width="36"
        height="36"
        alt="Good image"
      />

      <div class="product__info">
              <span class="product__quantity">
                {{ group.quantity }} шт.
              </span>
        <div class="order__location">
          <img
            class="product__image location__icon"
            src="./assets/icon/target.png"
            width="10"
            height="10"
            alt="Target icon"
          />
          {{ group.location }}
        </div>
      </div>
    </div>
    <div class="print__buttons">
      <button class="button button__print button_colored" (click)="print({ name: group.group, category: data.name })">
        {{ 'button.print' | translate }}
      </button>
    </div>
  </div>
</div>
<hr>
