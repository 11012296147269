import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  apiUrl = environment.apiUrl;

  filters = [
    'paymentStatus',
    'processingStatus',
    'source',
    'productSku',
    'clientComment',
    'search',
    'ids',

    'name',
    'category',
    'klarnaAndCODForShipping',
    'byIds'
  ];

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get(path: string, params?): Observable<any> {

    // get orders filtered according to params
    if (params) {
      // url must be like orders?filter[category]=1234&filter[name]=hello&page=1&perPage=10
      return this.httpClient.get<any>(this.apiUrl + path, {
        params: Object.entries(params).reduce(
          (paramsHttp, [key, value]) =>
            paramsHttp.set(this.filters.includes(key) ? `filter[${key}]` : key, value.toString()), new HttpParams()
        )
      })
        .pipe(
          catchError(error => {
            console.log('Error: ', error.message);
            return throwError(error);
          })
        );
    }
    // get all orders
    return this.httpClient.get<any>(this.apiUrl + path)
      .pipe(
        catchError(error => {
          console.log('Error: ', error.message);
          return throwError(error);
        })
      );
  }

  downloadFile(path: string, params): any {
    return this.httpClient.post(this.apiUrl + path, {responseType: 'blob'}, {
      params: Object.entries(params).reduce(
        (paramsHttp, [key, value]) =>
          paramsHttp.set(this.filters.includes(key) ? `filter[${key}]` : key, value.toString()), new HttpParams()
      ),
      responseType: 'blob'
    })
      .pipe(
        catchError(error => {
          console.log('Error: ', error.message);
          return throwError(error);
        })
      );
  }
}
