import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {LocalizationService} from '../../../services/localization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // get category of translation from localization.ts
  translation = this.localizationService.useTranslation('Landing');

  constructor(
    private cookieService: CookieService,
    public localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    this.localizationService.initLocalization();
  }

  // клик по кнопке log out
  logout(): void {
    this.cookieService.delete('token', '/');

    location.replace('/login');
  }

  // handle language changing
  onLanguageChange(deviceValue: string): void {
    // set selected language (en, ru, de etc.)
    this.localizationService.translate.use(deviceValue);

    // set new language value into cookie
    this.localizationService.setLanguageIntoCookie(deviceValue);
  }

}
