<ng-container *ngIf="itemsList">
  <div
    class="wrapper__table mat-elevation-z8">
    <table mat-table [dataSource]="itemsList" class="mat-elevation-z8">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.order.createdAt}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> First Name, Name </td>
      </ng-container>

      <!-- Payment method Column -->
      <ng-container matColumnDef="paymentMethod">
        <th mat-header-cell *matHeaderCellDef> Payment </th>
        <td mat-cell *matCellDef="let element"> {{element.order.paymentMethod}} </td>
      </ng-container>

      <!-- Success status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <!-- Country Column -->
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef> Country </th>
        <td mat-cell *matCellDef="let element"> Country </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</ng-container>
