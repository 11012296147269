<div class="wrapper wrapper__layout" *ngIf="dataSource; else loadingBlock">

  <div class="menu">
    <div class="menu__container">
      <div class="menu action-menu">
        <!--  REFRESH BUTTON-->
        <img
          src="./assets/icon/refresh-icon.svg"
          alt="Refresh icon"
          width="30" height="30"
          class="icon icon__refresh"
          (click)="refreshOrders()"
        >

        <!--  ORDERS COUNT-->
        <span class="count-info">{{ paginatorData.pageSize }} из {{ paginatorData.length }}</span>

        <!-- FILTER block -->
        <div class="filter filter__search">
          <!--          <img src="./assets/icon/search-icon.svg" alt="Search icon" width="30" height="30">-->
          <mat-form-field>
            <mat-label>{{ 'placeholder.searchInput' | translate }}</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="menu__container">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Apply to selected</mat-label>
          <mat-select [(value)]="selectedAction">
            <mat-option *ngFor="let action of actions" [value]="action.handler">
              {{action.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button (click)="handleAction()">Do action</button>
      </div>

      <!-- FILTER BUTTON -->
      <div class="filter__sort">
        <mat-form-field appearance="fill" class="color select__menu">
          <mat-label>{{ 'placeholder.selectFilter.label' | translate }}</mat-label>
          <mat-select>
            <mat-option value="option1">{{ 'placeholder.selectFilter.paid' | translate }}</mat-option>
            <mat-option value="option2">{{ 'placeholder.selectFilter.prepaid' | translate }}</mat-option>
            <mat-option value="option3">{{ 'placeholder.selectFilter.withComments' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingBlock>
  <div style="height: 20rem; width: 100%; font-size: 5rem; display: flex; justify-content:center; align-items: center"
  >
    <p>{{ ('service.loading' | translate | titlecase) + '...'}}</p>
  </div>
</ng-template>

<div
  *ngIf="dataSource"
  class="table my-table">
  <table mat-table
         [dataSource]="dataSource" multiTemplateDataRows
         class="">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; index as i"
                  [ngSwitch]="column">

      <ng-container *ngSwitchCase="'select'">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'id'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ecwidId}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'createDate'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{getShortDate(element.createdAt)}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'firstName'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.payer[column]}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'lastName'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.payer[column]}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'productName'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.products[0].name}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'productTotalQuantity'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.products.length}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'totalCost'">
        <th class="total-price__header-title" mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td class="total-price__value" mat-cell *matCellDef="let element">
          {{element[column]}} <strong class="total-price__currency">EUR</strong>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'deliveryOption'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{getDeliveryService(element)}} </td>
      </ng-container>

      <ng-container *ngSwitchCase="'isPaid'">
        <th class="payment-status" mat-header-cell *matHeaderCellDef>{{tableTitles[i] | translate}}</th>
        <td mat-cell *matCellDef="let element" class="select__cell">
          <mat-form-field appearance="fill" class="form-field select__payment-status select__payment-confirmation">
            <mat-select [value]="element.isPaid ? 'Yes' : 'No'" class="select" (click)="changePayment($event)">
              <mat-option value="Yes"
                          class="select__option">Yes</mat-option>
              <mat-option value="No"
                          class="select__option">No</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'paymentMethod'">
        <th class="payment__way" mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element" class="select__cell">
          <mat-form-field appearance="fill" class="form-field select__payment-way">
            <mat-select [ngModel]="element.paymentMethod" class="select">
              <mat-option value="{{element.paymentMethod}}"
                          class="select__option">{{ element.paymentMethod }}</mat-option>
              <mat-option value="value2" class="select__option">Option paymentService</mat-option>
              <mat-option value="value3" class="select__option">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'processingStatus'">
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td mat-cell *matCellDef="let element" class="select__cell select__payment">
          <mat-form-field appearance="fill" class="form-field select__payment-status">
            <mat-select [ngModel]="element.processingStatus" class="select">
              <mat-option value="{{element.processingStatus}}"
                          class="select__option">{{ element.processingStatus }}</mat-option>
              <mat-option value="value2" class="select__option">Option 2</mat-option>
              <mat-option value="value3" class="select__option">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'country'">
        <th class="countries__header-title" mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate}} </th>
        <td class="countries__name" mat-cell *matCellDef="let element"> {{element.payer.address.country}} </td>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <th mat-header-cell *matHeaderCellDef> {{tableTitles[i] | translate | titlecase}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" class="my-expanded">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <form
            [formGroup]="getForm(element)"
            (ngSubmit)="onSubmit()"
            class="form">

            <table class="client">
              <tr class="expanded-section__row">

                <td class="client-info">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{formFields.name.title | translate}}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.name.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td class="client-info">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ formFields.lastName.title | translate }}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.lastName.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td class="client-info client-info_email">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ formFields.email.title | translate }}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.email.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td class="client-info client-info_phone">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ formFields.phone.title | translate }}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.phone.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="client-info client-info_address">
                    <mat-label>{{formFields.address.title | translate}}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.address.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
              </tr>

              <tr class="expanded-section__row">
                <td class="client-info">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{formFields.orderNumberEcwid.title | translate}}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.orderNumberEcwid.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td class="client-info">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{formFields.trackNumber.title | translate}}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.trackNumber.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
                <td class="client-info">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{formFields.parcelNumberUPS.title | translate}}</mat-label>
                    <label>
                      <input matInput
                             formControlName="{{ formFields.parcelNumberUPS.key }}"
                      >
                    </label>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <mat-form-field class="example-full-width">
                  <mat-label>{{ formFields.comment.title | translate }}</mat-label>
                  <textarea matInput formControlName="{{formFields.comment.key}}"></textarea>
                </mat-form-field>
              </tr>
              <tr>
                <mat-form-field class="example-full-width">
                  <mat-label>{{ formFields.managersComment.title | translate }}</mat-label>
                  <textarea matInput formControlName="{{formFields.managersComment.key}}"></textarea>
                </mat-form-field>
              </tr>
            </table>
            <div class="buttons-container">
              <button type="submit"
                      class="button button__submit button__submit_true">{{ 'button.submit' | translate }}</button>
              <button type="button" (click)="returnFormValues()" class="button button__submit button__submit_false">
                {{ 'button.cancel' | translate }}
              </button>
            </div>
          </form>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="flex"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="flex example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (dblclick)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>

<mat-paginator
  class="mat-paginator-container"
  [length]="paginatorData.length"
  [pageSize]="paginatorData.pageSize"
  [pageSizeOptions]="paginatorData.pageSizeOptions"
  (page)="handlePaginatorEvents($event)">
</mat-paginator>
