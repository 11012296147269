import { Injectable } from '@angular/core';
import { localization } from '../../assets/localization/localization';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class LocalizationService {
  constructor(
    private cookieService: CookieService,
    public translate: TranslateService
  ) {

  }

  initLocalization(): void {
    this.translate.addLangs(['en', 'ru', 'de']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();

    this.translate.use(browserLang.match(/en|ru|de/) ? browserLang : 'en');
  }

  // положить в куки переменную языка
  setLanguageIntoCookie(language: string): void {
    this.cookieService.set('language', language);
  }

  // взять из куки переменную языка
  getLanguageFromCookie(): string {
    return this.cookieService.get('language');
  }
  // getLangListFromCookie(): any {
  //   return this.cookieService.get('langList');
  // }

  useTranslation(namespace: string): any {
    // let language = this.getLanguageFromCookie();
    // console.log('language from cookie: ', language);
    return key => localization[this.getLanguageFromCookie()][namespace][key];
  }

  deleteCookie(): void {
    this.cookieService.delete('layoutLanguage');
  }

  // todo: realize new functionality with @ngx-translate


}
