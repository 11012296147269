import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {OrderModel} from '../../models/order/order.model';
import {OrdersAPIResponseModel} from '../../models/order/ordersAPIResponse.model';
import {ApiService} from '../../services/api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', position: 'static'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class OrdersTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() page: EventEmitter<PageEvent> = new EventEmitter(); // todo Delete if no used.
  @Input() filterParams: {
    search?: any
  } = {};

  dataSource: MatTableDataSource<OrderModel>;

  ordersList = [];

  selection = new SelectionModel<OrderModel>(true, []);

  expandedElement: OrderModel | null;

  isPaid = 'Yes';

  // column titles
  columnsToDisplay = ['select', 'id', 'createDate', 'firstName', 'lastName', 'productName', 'productTotalQuantity', 'totalCost', 'deliveryOption', 'paymentMethod', 'isPaid', 'processingStatus', 'country'];
  tableTitles = ['check', 'table.number', 'table.date', 'table.name', 'table.lastName', 'table.product', 'table.quantity', 'table.price', 'table.delivery', 'table.paymentMethod', 'table.payment', 'table.status', 'table.country'];

  actions = [
    {
      name: 'ship',
      handler: 'shipSelectedOrders'
    },
    {
      name: 'testAction',
      handler: 'testActionFunc'
    }
  ];

  selectedAction = 'shipSelectedOrders';

  // mat paginator
  paginatorData = {
    pageSize: 20,
    length: 100,
    pageSizeOptions: [10, 20, 50, 100]
  };

  formFields = {
    name: {
      key: 'name', title: 'form.name'
    },
    lastName: {
      key: 'lastName', title: 'form.lastName'
    },
    email: {
      key: 'email', title: 'form.email'
    },
    phone: {
      key: 'phone', title: 'form.phone'
    },
    address: {
      key: 'address', title: 'form.address'
    },
    orderNumberEcwid: {
      key: 'orderNumberEcwid', title: 'form.number'
    },
    trackNumber: {
      key: 'trackNumber', title: 'form.trackNumber'
    },
    parcelNumberUPS: {
      key: 'parcelNumberUPS', title: 'form.parcelNumber'
    },
    comment: {
      key: 'comment', title: 'form.clientComment'
    },
    managersComment: {
      key: 'managersComment', title: 'form.managerComment'
    },
  };

  constructor(
    protected apiService: ApiService,
    protected formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    // получить данные с апи
    this.getOrders(this.filterParams);
  }

  ngAfterViewInit(): void {
    this.initPaginator();

  }

  // get orders list from server
  getOrders(params: object = {page: 1, perPage: 20}): void {
    // получить данные с апи
    // преобразовать их в заказы
    this.apiService.get('orders', params).subscribe((ordersList: OrdersAPIResponseModel) => {
      // parse response and accumulate orders into ordersArray
      this.insertDataIntoTable(ordersList.items);

      // set pagination data
      this.paginatorData.length = ordersList.total;
      this.paginatorData.pageSize = ordersList.perPage;
    });
  }

  getShortDate(time): string {
    const myDate = new Date(time).toString().split(' ');
    return myDate[2] + ' ' + myDate[1] + ' ' + myDate[3].slice(2, 4);
  }

  insertDataIntoTable(table): void {
    // передать в таблицу заказы
    this.dataSource = new MatTableDataSource<OrderModel>(table);
  }

  initPaginator(): void {
    // создать пагинатор со значениями по умолчанию(20 заказов на странице, страница номер 1)
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Items per page: ';
  }

  handlePaginatorEvents(event: PageEvent): void {
    // extract options from pageEvent
    const options = {
      page: event.pageIndex + 1,
      perPage: event.pageSize
    };
    // reset cache
    this.dataSource = null;
    // send options in request
    this.getOrders(options);
    // this.getOrders2(options);
  }

  // open all expandable rows
  expandAll(): void {
    console.log('expand all');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: OrderModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.filterParams.search = filterValue.trim();
  }

  // submit form
  onSubmit(): void {
    // todo: submit form
    // console.log('FORM: ', this.form);
  }

  returnFormValues(): void {
    // todo: set back default form values
    console.log('cancel');
  }

  refreshOrders(): void {
    this.insertDataIntoTable([]);
    this.getOrders(this.filterParams);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changePayment(event: Event): void {
    // todo: handle payment status
  }

  // parse response functions
  // todo: необходимо получать из респонса одно слово, а не предложение. По изменении респонса удалить этот метод
  getDeliveryService(order): string {
    return order.deliveryService.split(' ')[0];
  }

  getPayment(status): string {
    if (status) {
      return 'Yes';
    }
    return 'No';
  }

  getForm(responseOrder): FormGroup {
    return this.formBuilder.group({
      name: responseOrder.payer.firstName,
      lastName: responseOrder.payer.lastName ? responseOrder.payer.lastName : responseOrder.payer.secondName,
      email: responseOrder.payer.email,
      phone: responseOrder.payer.phone,
      address: responseOrder.payer.address.address,
      orderNumberEcwid: responseOrder.ecwidId,
      trackNumber: responseOrder.source, // response have no tracking number, is given order.source field
      parcelNumberUPS: responseOrder.source, // response have no parcel Number UPS, is given order.source field
      comment: responseOrder.clientComment,
      managersComment: responseOrder.managerComment,
    });
  }

  shipSelectedOrders(): void {
    console.log('shipSelectedOrders', this.selection);

    const ordersIds = this.selection.selected.map(value => {
      return value.id;
    });

    const params = {
      category: 'byIds',
      ids: ordersIds
    };

    this.sentMassHandlingRequest(params);

  }

  testActionFunc(): void {
    console.log('Test function', this.selection.hasValue());
  }

  handleAction(): void {
    console.log('handleAction');

    if (this.selection.hasValue()) {
      this[this.selectedAction]();
    }
  }

  sentMassHandlingRequest(params): void {
    // send category name and product name to the server
    this.apiService.downloadFile('orders/massHandling', params).subscribe(response => {

      // create date for the file name
      const date = new Date();
      // generate string like '04-12-2020 Bear'
      const dateName = date.getDate().toString() + '-' + date.getMonth().toString() + '-' + date.getFullYear() + ' ' + params.name;
      // concatenate file name
      const blob: any = new Blob([response], {type: 'application/vnd.ms-word'});
      fileSaver.saveAs(blob, dateName + '.docx');
    });
  }
}
